<template>
  <div id="terminal_log_component">
    <div class="terminal_log_container">
      <div class="terminal_log_container__item title_container">
        <div class="title_container__item title_status_container">
          <div class="title_status_container__item title">
            <label>Postmortem</label>
          </div>
          <div v-if="showLoad" class="title_status_container__item">
            <div class="spring-spinner">
              <div class="spring-spinner-part top">
                <div class="spring-spinner-rotator"></div>
              </div>
              <div class="spring-spinner-part bottom">
                <div class="spring-spinner-rotator"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="terminal_log_container__item task_status_container">
        <action-bar
          :show="actionBarData.main.show"
          @close-action-bar="resetActionBar('main')"
          :status="actionBarData.main.status"
          :action="actionBarData.main.action"
          :broadDescription="actionBarData.main.broadDescription"
        />
      </div>
      <div
        v-if="logs.length === 0"
        class="terminal_log_container__item not_found"
      >
        Не найдено
      </div>
      <div class="terminal_log_container__item log_container">
        <div
          v-for="(el, i) in logs"
          :key="i"
          class="logs_container__item log_line_container"
          :class="showNewLog && i === 0 ? 'selected' : ''"
        >
          <div class="log_line_container__item log_info_container">
            <div class="log_info_container__item task_id">
              <label>ID: {{ el.taskId }}</label>
            </div>
            <div class="log_info_container__item title">
              <label>Date: {{ el.date }}</label>
            </div>
            <div
              class="log_info_container__item task_status"
              :class="`task_status_${el.taskState.toLowerCase()}`"
            >
              <label>{{ el.taskState }}</label>
            </div>
          </div>

          <div class="log_line_container__item buttons_container">
            <div
              class="buttons_container__item"
              :class="el.taskState !== 'OK' ? 'disable_button' : ''"
            >
              <button
                v-if="el.taskState === 'OK'"
                title="Скачать скриншот"
                @click="onDownload(event, el.screenshot)"
              >
                <svg
                  fill="#000000"
                  transform="scale(1.2 1.2)"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M22.71,6.29a1,1,0,0,0-1.42,0L20,7.59V2a1,1,0,0,0-2,0V7.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l3,3a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l3-3A1,1,0,0,0,22.71,6.29ZM19,13a1,1,0,0,0-1,1v.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.7L9.41,11.12a2.85,2.85,0,0,0-3.93,0L4,12.6V7A1,1,0,0,1,5,6h8a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V14A1,1,0,0,0,19,13ZM5,20a1,1,0,0,1-1-1V15.43l2.9-2.9a.79.79,0,0,1,1.09,0l3.17,3.17,0,0L15.46,20Zm13-1a.89.89,0,0,1-.18.53L13.31,15l.7-.7a.77.77,0,0,1,1.1,0L18,17.21Z"
                    ></path>
                  </g>
                </svg>
              </button>
              <button v-else title="Скачать скриншот">
                <svg
                  fill="#a1a3ab"
                  transform="scale(1.2 1.2)"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M22.71,6.29a1,1,0,0,0-1.42,0L20,7.59V2a1,1,0,0,0-2,0V7.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l3,3a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l3-3A1,1,0,0,0,22.71,6.29ZM19,13a1,1,0,0,0-1,1v.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.7L9.41,11.12a2.85,2.85,0,0,0-3.93,0L4,12.6V7A1,1,0,0,1,5,6h8a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V14A1,1,0,0,0,19,13ZM5,20a1,1,0,0,1-1-1V15.43l2.9-2.9a.79.79,0,0,1,1.09,0l3.17,3.17,0,0L15.46,20Zm13-1a.89.89,0,0,1-.18.53L13.31,15l.7-.7a.77.77,0,0,1,1.1,0L18,17.21Z"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
            <div
              class="buttons_container__item"
              :class="el.taskState !== 'OK' ? 'disable_button' : ''"
            >
              <button
                v-if="el.taskState === 'OK'"
                title="Скачать логи"
                @click="onDownload(event, el.logs)"
              >
                <svg
                  viewBox="0 0 24 24"
                  transform="scale(1.2 1.2)"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M13.5 3H12H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H7.5M13.5 3L19 8.625M13.5 3V7.625C13.5 8.17728 13.9477 8.625 14.5 8.625H19M19 8.625V9.75V12V19C19 20.1046 18.1046 21 17 21H16.5"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M12 12V20M12 20L9.5 17.5M12 20L14.5 17.5"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                </svg>
              </button>
              <button v-else title="Скачать логи">
                <svg
                  viewBox="0 0 24 24"
                  transform="scale(1.2 1.2)"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M13.5 3H12H7C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H7.5M13.5 3L19 8.625M13.5 3V7.625C13.5 8.17728 13.9477 8.625 14.5 8.625H19M19 8.625V9.75V12V19C19 20.1046 18.1046 21 17 21H16.5"
                      stroke="#a1a3ab"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M12 12V20M12 20L9.5 17.5M12 20L14.5 17.5"
                      stroke="#a1a3ab"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
            <div class="buttons_container__item">
              <button title="Удалить" @click="onDelete(el.taskId)">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.83301 15.5C2.37467 15.5 1.98245 15.3369 1.65634 15.0108C1.32967 14.6842 1.16634 14.2917 1.16634 13.8333V3H0.333008V1.33333H4.49967V0.5H9.49967V1.33333H13.6663V3H12.833V13.8333C12.833 14.2917 12.67 14.6842 12.3438 15.0108C12.0172 15.3369 11.6247 15.5 11.1663 15.5H2.83301ZM11.1663 3H2.83301V13.8333H11.1663V3ZM4.49967 12.1667H6.16634V4.66667H4.49967V12.1667ZM7.83301 12.1667H9.49967V4.66667H7.83301V12.1667Z"
                    fill="#010101"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "action-bar": defineAsyncComponent(() =>
      import("@/components/ActionBar.vue")
    ),
  },
  props: {
    terminalId: {
      type: Number,
    },
    online: {
      type: Boolean,
    },
  },
  data() {
    return {
      logs: [],
      showLoad: false,
      showNewLog: false,
      actionBarData: {
        main: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
      },
    };
  },
  methods: {
    onDownload(event, url) {
      window.open(url, "_blank");
    },

    // delete log
    onDelete(taskId) {
      this.actionBarData.main = {
        status: "WAITING",
        action: "Удаление postmortem",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/task?taskId=${taskId}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (postmortem delete data)",
            res
          );
          if (res.status === 200) {
            this.actionBarData.main.status = "OK";
            this.logs = this.logs.filter((e) => e.taskId !== Number(taskId));
            setTimeout(() => {
              this.resetActionBar("main");
            }, 4000);
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to delete log", error);
          if (error.request.status === 403) {
            this.actionBarData.main.status = "ERROR";
            (this.actionBarData.main.action = "Удаление postmortem"),
              (this.actionBarData.main.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = error.data;
        });
    },

    // gets all logs
    getLogs() {
      this.showLoad = true;
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/tasks?terminalId=${this.terminalId}&taskType=postmortem`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (postmortem data)",
            res
          );
          const data = [];
          res.data.payload.tasks.forEach((el) => {
            data.push({
              date: el.createDttm,
              logs:
                el.taskData.result !== null ? el.taskData.result.logs : null,
              screenshot:
                el.taskData.result !== null
                  ? el.taskData.result.screenshot
                  : null,
              taskId: el.taskId,
              taskState: el.taskState,
            });
          });
          this.logs = data.sort((a, b) => {
            return (
              Date.parse(b.date, "yyyy-MM-dd HH:mm:ss") -
              Date.parse(a.date, "yyyy-MM-dd HH:mm:ss")
            );
          });
          this.showLoad = false;
        })
        .catch((error) => {
          console.log("Error to get logs data data", error);
        });
    },

    resetActionBar(type) {
      this.actionBarData[type] = {
        status: "",
        action: "",
        broadDescription: "",
        show: false,
      };
    },
  },
  computed: {
    anyWaitingTask() {
      return this.actionBarData.main.status === "WAITING";
    },
  },

  mounted() {
    this.getLogs();
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/animations.less";

.terminal_log_container {
  display: flex;
  flex-direction: column;
  padding: 25px 25px;

  .not_found {
    display: flex;
    justify-content: center;
  }

  .buttons_container {
    display: flex;
    justify-content: space-between;
    .disable_button {
      button {
        &:hover {
          pointer-events: none;
        }
        &:active {
          pointer-events: none;
          box-shadow: none;
        }
      }
    }
    button {
      all: unset;
      padding: 5px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      cursor: pointer;
      &.disable {
        &:hover {
          background-color: unset;
          cursor: unset;
        }
      }
      &:hover {
        background-color: #4151b720;
        border-radius: 10px;
        transition: 0.5s;
      }
    }
  }
}

.title_container {
  display: flex;
  justify-content: space-between;
}

.log_container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  overflow-y: scroll;
  max-height: 70vh;
  padding-right: 10px;
}

.log_line_container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #c9c9c9;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10.5px;
  padding: 5px;

  &.selected {
    background: #f1f6ff;
    border-radius: 5px;
    padding: 5px;
    border-bottom: unset;
  }
}

.title_status_container {
  display: flex;
  align-items: center;

  .title {
    margin-right: 15px;
  }
}

.task_status_container {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  min-height: 40px;
  display: flex;
  justify-content: center;
}

.log_info_container {
  display: flex;
  align-items: center;
  .log_info_container__item {
    margin-right: 20px;
    &.task_id {
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.title {
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }

  .task_status {
    padding: 3px 16px;
    height: 10px;
    border-radius: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    color: #ffffff;

    &.task_status_pending {
      background-color: #4150b7;
    }
    &.task_status_waiting {
      background-color: #f0ad4e;
    }
    &.task_status_ok {
      background-color: #5bc367;
    }
    &.task_status_error {
      background-color: #f44c4b;
    }
    &.task_status_inprocess {
      background-color: #4150b7;
    }
  }
}
</style>
